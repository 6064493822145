/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './theme/custom-colors.scss';
@import './theme/custom-font.scss';
@import './theme/custom.scss';
@import './theme/app-defaults.scss';

.ion-tab-button {
  .tab-item-active {
    background: var(--ion-color-theme);
    border-radius: 50%;
    width: 138%;
  }
}

ion-spinner {
  stroke: #ff0000;
  fill: #8b0000;
  position: fixed;
  z-index: 999;
  height: 5em;
  width: em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

ion-col.center {
  display: flex;
  justify-content: center;
}

ion-col.right {
  display: flex;
  justify-content:end;
  text-align: end;
  width:100%;
}

.uploadcare--dialog {
  padding-top: 50px !important;
}

ion-icon.sortfilter {
  font-size: 23px;
}

ion-label.filterlabel {
  font-size: 12px !important;
}

ion-input.filterlabel {
  font-size: 12px !important;
  text-align: right;
}

ion-select.filterlabel {
  font-size: 12px !important;
}