.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-30 {
  font-size: 30px !important;
}

.w-25-per {
  width: 25%;
}

.w-50-per {
  width: 50%;
}

.w-75-per {
  width: 75%;
}

.w-100-per {
  width: 100%;
}

.m-0-auto {
  margin: 0 auto !important;
}

.pl-5-px {
  padding-left: 5px;
}

.pl-10-px {
  padding-left: 10px;
}

.pt-10-px {
  padding-top: 10px;
}

.ml-5-px {
  margin-left: 5px;
}

.ml-22-px {
  margin-left: 22px;
}

.mb-30-px {
  margin-bottom: 30px !important;
}

.mb-3-em {
  margin-bottom: 3em;
}

.pb-3-em {
  padding-bottom: 3em;
}

.pb-custom {
  padding-bottom: 4em;
}

.ips-0-px {
  --padding-start: 0;
}

.br-1-px {
  border-right: 1px solid var(--ion-color-light-gray);
}

.br-10-px {
  border-radius: 10px;
  --border-radius: 10px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.no-ripple {
  --ripple-color: transparent;
}

.color-white {
  color: #FFFFFF !important;
}

.color-black {
  color: #000000 !important;
}

.color-818181 {
  color: #818181 !important;
}

.color-A3A3A3 {
  color: #A3A3A3 !important;
}

.color-2A2A2A {
  color: #2A2A2A !important;
}

.color-1E1E1E {
  color: #1E1E1E !important;
}

.color-lightgray {
  color: var(--ion-color-light-gray) !important;
}

.ff-poppins-bold {
  font-family: poppins-bold, sans-serif !important;
}

.ff-poppins-regular {
  font-family: poppins-regular, sans-serif !important;
}

.ff-poppins-medium {
  font-family: poppins-medium, sans-serif !important;
}

.ff-poppins-semiBold {
  font-family: poppins-semiBold, sans-serif !important;
}

.ff-openSans-bold {
  font-family: openSans-bold, sans-serif !important;
}

.ff-openSans-regular {
  font-family: openSans-regular, sans-serif !important;
}

.ff-raleway-bold {
  font-family: raleway-bold, sans-serif !important;
}

.box-shadow {
  box-shadow: 0 3px 15px rgb(0 0 0 / 20%) !important;
  position: relative;
  z-index: 999;
}

.validation-errors {
  padding: 5px 0 0 2.5em;

  .error-message {
    color: var(--ion-color-danger);
    font-size: 14px;
  }
}

.underline {
  border-bottom: 1px solid #ddd;
}

.bgTransparent {
  background: transparent;
  --background: transparent;
}

.alert-modal-class {
  .modal-wrapper {
    --height: 25%;
    position: absolute;
    display: block;
    width: 90%;
    border-radius: 16px;
  }
}

.footer-md::before {
  background-image: none;
}

.species-class {
  .modal-wrapper {
    --height: 50%;
    position: absolute;
    display: block;
    width: 80%;
    border-radius: 18px;
    top: 27%;
    background: transparent;
  }
}
